export const ServerVarsModel = {
  dMGScaleDown: 0.1,
  helperInefficiency: 0.023,
  helperInefficiencySlowDown: 34,
  helperUpgradeBase: 1.082,
  maxHelperFormulaInt: 34,
  evolveCostMultiplier: 1000,
  petPassiveLevelGap: 5,
  petPassiveLevelIncrement: 0.01,
  petDamageIncLevel1: 40,
  petDamageIncLevel2: 80,
  playerDamageMult: 1,
  playerCritChance: 0.002,
  maxCritChance: 0.6,
  playerCritMinMult: 3,
  playerCritMaxMult: 20,
  clanBonusBase: 1.1,
  clanMemoryBase: 0.001,
  clanBonusBaseNerf: 1.05,
  clanMemoryBaseNerf: 0.0005,
  clanQuestStageNerf: 200,
  maxMemoryAmount: 0.5,
  petTapAmount: 20,
  petAutoAttackLevel: 500,
  maxPetSpeedBoost: 10,
  petAutoAttackDuration: 3,
  monsterCountBase: 10,
  monsterCountInc: 4,
  maxMonsterCount: 30,
  monsterHPMultiplier: 17.5,
  monsterHPBase1: 1.39,
  monsterHPBase2: 1.13,
  monsterHPLevelOff: 115,
  themeMultiplierSequence: [2, 3, 4, 5, 8],
  monsterGoldMultiplier: 0.008,
  monsterGoldSlope: 0.0002,
  noMoreMonsterGoldSlope: 150,
  maxBossGoldMultiplier: 3,
  bossGoldMultiplier: 2,
  bossGoldMultiplierSlope: 0.2,
  treasureGold: 15,
  chestersonChance: 0.01,
  multiMonsterBaseChance: 0.01,
  goldx10Chance: 0.005,
  playerUpgradeCostGrowth: 1.062,
  playerUpgradeCostBase: 5,
  artifactCostBase: 1.31,
  relicFromStageBaseline: 75,
  relicFromStageDivider: 14,
  relicFromStagePower: 1.75,
  bossHPModBase: 1.1,
  inactiveGoldMonsterCap: 30000,
  offlineKillAnimationTime: 0.7,
  maxStage: 4000,
};
var createHmac = require('create-hmac')
var checkParameters = require('./precondition')

exports.pbkdf2 = function (password, salt, iterations, keylen, digest, callback) {
  if (typeof digest === 'function') {
    callback = digest
    digest = undefined
  }

  checkParameters(iterations, keylen)
  if (typeof callback !== 'function') throw new Error('No callback provided to pbkdf2')

  setTimeout(function () {
    callback(null, exports.pbkdf2Sync(password, salt, iterations, keylen, digest))
  })
}

var defaultEncoding
if (process.browser) {
  defaultEncoding = 'utf-8'
} else {
  var pVersionMajor = parseInt(process.version.split('.')[0].slice(1), 10)

  defaultEncoding = pVersionMajor >= 6 ? 'utf-8' : 'binary'
}

exports.pbkdf2Sync = function (password, salt, iterations, keylen, digest) {
  if (!Buffer.isBuffer(password)) password = new Buffer(password, defaultEncoding)
  if (!Buffer.isBuffer(salt)) salt = new Buffer(salt, defaultEncoding)

  checkParameters(iterations, keylen)

  digest = digest || 'sha1'

  var hLen
  var l = 1
  var DK = new Buffer(keylen)
  var block1 = new Buffer(salt.length + 4)
  salt.copy(block1, 0, 0, salt.length)

  var r
  var T

  for (var i = 1; i <= l; i++) {
    block1.writeUInt32BE(i, salt.length)
    var U = createHmac(digest, password).update(block1).digest()

    if (!hLen) {
      hLen = U.length
      T = new Buffer(hLen)
      l = Math.ceil(keylen / hLen)
      r = keylen - (l - 1) * hLen
    }

    U.copy(T, 0, 0, hLen)

    for (var j = 1; j < iterations; j++) {
      U = createHmac(digest, password).update(U).digest()
      for (var k = 0; k < hLen; k++) T[k] ^= U[k]
    }

    var destPos = (i - 1) * hLen
    var len = (i === l ? r : hLen)
    T.copy(DK, destPos, 0, len)
  }

  return DK
}

import React from 'react';

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className='header'>
        </div>
      </header>
    );
  }
}

export default Header;